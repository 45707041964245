import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type ButtonSize = 'small' | 'medium' | 'large';
type ButtonVariant = 'primary' | 'secondary' | 'dark' | 'hollow' | 'success' | 'info';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  $size?: ButtonSize;
  $variant?: ButtonVariant;
  $fullWidth?: boolean;
  icon?: IconDefinition;
  iconPosition?: 'left' | 'right';
}

const ButtonWrapper = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-1);
  font-family: var(--font-family-default);
  border: none;
  cursor: pointer;
  transition: 0.2s ease-out;
  transform-origin: center;
  white-space: nowrap;
  box-shadow: 0 6px 12px var(--greenD-10);

  ${props => props.$fullWidth && css`
    width: 100%;
  `}

  ${props => {
    switch (props.$size) {
      case 'small':
        return css`
          padding: 0.5em 1em;
          border-radius: 0.5em;
          font-size: var(--font-size-h6);
        `;
      case 'large':
        return css`
          padding: 1.5em 3em;
          border-radius: 3em;
          font-size: var(--font-size-h4);
        `;
      default:
        return css`
          padding: 1em 2em;
          border-radius: 2em;
          font-size: var(--font-size-default);
        `;
    }
  }}

  ${props => {
    switch (props.$variant) {
      case 'secondary':
        return css`
          background: var(--secondary);
          color: var(--purpleB);
          &:hover {
            filter: brightness(1.2);
          }
        `;
      case 'dark':
        return css`
          background: inherit;
          color: var(--purpleB);
          &:hover {
            background: var(--dark);
          }
        `;
      case 'hollow':
        return css`
          background: inherit;
          color: var(--purpleB);
          outline: 1px solid var(--primary);
          outline-offset: -1px;
          &:hover {
            background: var(--dark);
          }
        `;
      case 'success':
        return css`
          background: var(--success);
          color: var(--white);
        `;
      case 'info':
        return css`
          background: var(--info);
          color: var(--white);
        `;
      default:
        return css`
          background: var(--primary);
          color: var(--purpleB);
        `;
    }
  }}

  &:hover {
    filter: brightness(1.1);
    transform: scale(1.025);
    transition: none;
  }

  &:active {
    transform: scale(1);
    filter: brightness(0.9);
  }

  &:disabled {
    opacity: 50%;
    pointer-events: none;
  }
`;

const Button: React.FC<ButtonProps> = ({ 
  $size, 
  $variant, 
  $fullWidth,
  children, 
  icon, 
  iconPosition = 'left', 
  ...props 
}) => {
  return (
    <ButtonWrapper $size={$size} $variant={$variant} $fullWidth={$fullWidth} {...props}>
      {icon && iconPosition === 'left' && <FontAwesomeIcon icon={icon} />}
      {children}
      {icon && iconPosition === 'right' && <FontAwesomeIcon icon={icon} />}
    </ButtonWrapper>
  );
};
export default Button;
