import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";
import MobileLogo from "./logos/yarden_mark_bg_fitted.svg";
import DesktopLogo from "./logos/yarden_horizontal_lockup_bg_fitted.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faPhone } from "@fortawesome/free-solid-svg-icons";

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 99999;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);

  a {
    color: var(--purpleB);
  }
`;

const NavbarLogo = styled.img`
  cursor: pointer;
  height: var(--space-3);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: url(${MobileLogo});

  @media screen and (min-width: 480px) {
    content: url(${DesktopLogo});
  }

  @media screen and (min-width: 768px) {
    position: relative;
    left: unset;
    top: unset;
    transform: unset;
  }
`;

const MobileNavButton = styled.button`
  border: 1px solid var(--purpleB);
  padding: 1rem 1.5rem;
  font-size: 1.5em;
  border-radius: 1rem;
  background: white;
  border-color: var(--greenD-10);

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--space-2);
  max-width: 1366px;
  margin: 0 auto;
`;

const Marquise = styled.span`
  width: 100vw;
  height: fit-content;
  color: white;
  background: var(--purpleB);
  font-size: var(--font-size-small);
  text-align: center;
`;

const CallUsButton = styled.a`
  color: var(--info);
  padding: 0.5em 1em;
  border-radius: 2em;
  border: 1px solid var(--green0);
  box-shadow: 0 6px 12px var(--greenD-10);
  display: flex;
  align-items: center;
  gap: var(--space-1);
  margin-left: var(--space-1);

  @media screen and (min-width: 768px) {
    margin-right: var(--space-1);
  }
`;

const DesktopNavWrapper = styled.div`
  display: flex;
`;

const Header = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const headerRef = useRef(null);
  const mobileNavRef = useRef(null);

  const toggleMobileNav = () => {
    setShowMobileNav(!showMobileNav);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        showMobileNav &&
        mobileNavRef.current &&
        !(mobileNavRef.current as HTMLElement).contains(event.target as Node) &&
        !(event.target as HTMLElement).closest("#mobile-nav-button")
      ) {
        setShowMobileNav(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMobileNav]);

  useEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        const height = (headerRef.current as HTMLElement).offsetHeight;
        document.documentElement.style.setProperty(
          "--header-height",
          `${height}px`
        );
      }
    };

    updateHeaderHeight();
    window.addEventListener("resize", updateHeaderHeight);

    return () => {
      window.removeEventListener("resize", updateHeaderHeight);
    };
  }, []);

  function renderCTAButton() {
    switch (window.location.pathname) {
      case "/":
        return (
          <div>
            <CallUsButton href="https://school.thegardenbootcamp.com/purchase?product_id=5742338">
              Sign Up
            </CallUsButton>
          </div>
        );
      case "/webinar":
        return (
          <div>
            <CallUsButton href="tel:+14152313183">
              <FontAwesomeIcon icon={faPhone} /> Call Us
            </CallUsButton>
          </div>
        );
      default:
        return <></>;
    }
  }

  return (
    <HeaderContainer ref={headerRef}>
      <Marquise>Call Us Now! 🇺🇸 (415) 231-3183</Marquise>
      <HeaderContent>
        <NavbarLogo
          onClick={() => (window.location.href = window.location.origin)}
        />

        <MobileNavButton id="mobile-nav-button" onClick={toggleMobileNav}>
          {showMobileNav ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </MobileNavButton>
        {showMobileNav && <MobileNav ref={mobileNavRef} />}
        <DesktopNavWrapper>
          <DesktopNav />
          {renderCTAButton()}
        </DesktopNavWrapper>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
