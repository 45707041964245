import React, { InputHTMLAttributes, forwardRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

const InputWrapper = styled.div<{ $fullWidth?: boolean }>`
  ${(props) => props.$fullWidth && "width: 100%;"}
`;

const InputLabel = styled.label`
  display: block;
  margin-left: 1em;
`;

const RequiredLabel = styled.span`
  color: var(--danger);
  margin-left: 0.25em;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputAdornment = styled.div<{ position: "start" | "end" }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.position === "start" ? "left: 1em;" : "right: 1em;")}
  color: var(--greenD-50);
`;

const StyledInput = styled.input<{
  $error?: string;
  hasAdornment?: "start" | "end" | "both";
  $isFocused?: boolean;
}>`
  padding: 1em;
  padding-left: ${(props) =>
    props.hasAdornment === "start" || props.hasAdornment === "both"
      ? "2.5em"
      : "1em"};
  padding-right: ${(props) =>
    props.hasAdornment === "end" || props.hasAdornment === "both"
      ? "2.5em"
      : "1em"};
  outline: none;
  border: none;
  border: 1px solid var(--greenD-25);
  background: var(--greenD-10);
  font-size: var(--font-size-default);
  width: 100%;
  transition: all 0.3s ease;
  border-radius: 1em;
  box-shadow: inset 0 6px 12px var(--greenD-10);

  &::placeholder {
    color: var(--greenD-25);
    opacity: 1;
  }

  &:focus {
    outline: 2px solid var(--purple0);
    outline-offset: -2px;
    transition: none;
    background: var(--green4);
  }

  &:hover:not(:focus) {
    background: var(--purpleD-10);
  }

  ${(props) =>
    props.$error &&
    `
    color: var(--danger);
    border-color: var(--danger);
  `}
`;

const HelperText = styled.div<{ $isFocused: boolean }>`
  margin-top: 0.5em;
  color: var(--purpleB);
  padding-inline: 0.5em;
  font-size: var(--font-size-small);
  display: ${(props) => (props.$isFocused ? "block" : "none")};
  font-family: var(--font-family-data);
`;

const ErrorText = styled.data`
  margin-top: 0.5em;
  color: var(--danger);
  padding-inline: 0.5em;
  font-size: var(--font-size-small);
`;

const InputIndicator = styled.div<{ $isValid: boolean }>`
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  color: ${(props) => (props.$isValid ? "var(--success)" : "var(--danger)")};
`;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  helperText?: string;
  $fullWidth?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  id: string;
  required?: boolean;
  pattern?: string;
  isValid?: boolean | null;
  $isFocused?: boolean;
}

export const Input: React.FC<InputProps> = forwardRef<
  HTMLInputElement,
  InputProps
>(
  (
    {
      label,
      error,
      helperText,
      $fullWidth,
      startAdornment,
      endAdornment,
      className,
      placeholder,
      required,
      pattern,
      isValid,
      $isFocused,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const hasAdornment =
      startAdornment && endAdornment
        ? "both"
        : startAdornment
        ? "start"
        : endAdornment
        ? "end"
        : undefined;

    return (
      <InputWrapper $fullWidth={$fullWidth} className={className}>
        {label && (
          <InputLabel htmlFor={props.id}>
            {label}
            {required && <RequiredLabel>*</RequiredLabel>}
          </InputLabel>
        )}
        <InputContainer>
          {startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          )}
          <StyledInput
            ref={ref}
            $error={error}
            hasAdornment={hasAdornment}
            placeholder={placeholder}
            required={required}
            pattern={pattern}
            $isFocused={$isFocused}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            {...props}
            id={props.id}
          />
          {endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          )}

          {isValid !== null && isValid !== undefined && (
            <InputIndicator $isValid={isValid}>
              <FontAwesomeIcon
                icon={isValid ? faCheckCircle : faExclamationCircle}
              />
            </InputIndicator>
          )}
        </InputContainer>

        {helperText && (
          <HelperText $isFocused={isFocused}>{helperText}</HelperText>
        )}
        {error && <ErrorText>{error}</ErrorText>}
      </InputWrapper>
    );
  }
);

Input.displayName = "Input";
