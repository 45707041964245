import { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

const SessionSelectorContainer = styled.div`
  position: relative;
  width: 100%;
  color: var(--purpleB);
`;

const Label = styled.label`
  margin-left: 1em;

  &::after {
    content: " *";
    color: var(--danger);
    margin-left: 0.2em;
  }
`;

const DropdownToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;
  border: 1px solid var(--greenD-10);
  border-radius: 2em;
  cursor: pointer;
  background: white;
  box-shadow: 0 6px 12px var(--greenD-10);
  z-index: 2;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-1);
`;

const CalendarIcon = styled.span`
  position: relative;
`;

const DropdownMenu = styled.ul<{ $isOpen: boolean }>`
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: white;
  border: 1px solid var(--greenD-10);
  border-radius: 0 0 1em 1em;
  list-style: none;
  padding: 0;
  z-index: 1;
  margin-top: var(--space-2);
  box-shadow: 0 6px 12px var(--greenD-10);
  transform-origin: top;
  transform: scaleY(${(props) => (props.$isOpen ? 1 : 0)});
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0.2s ease-out;
  padding-top: 2em;
  color: var(--purpleB);
`;

const DropdownItem = styled.li<{ $isToday: boolean }>`
  padding: 0.5em 1em;
  cursor: pointer;
  transition: 0.3s;
  transform-origin: center;

  &:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
      background: var(--green5);
      box-shadow: inset 0 6px 12px var(--green3);
      padding: 0.25em 0.75em;
      content: "${(props) => (props.$isToday ? "Today!" : "This Thursday!")}";
      color: var(--success);
      border-radius: 1em;
      font-size: var(--font-size-small);
    }
  }

  &:last-of-type {
    border-radius: 0 0 1em 1em;
  }

  &:hover {
    background-color: var(--purpleD-10);
    transition: none;
    transform: scale(1.02);
  }
`;

interface SessionSelectorProps {
  onSessionSelect: (session: string | null) => void;
  onNextSessionChange: (nextSession: moment.Moment) => void;
}

const SessionSelector: React.FC<SessionSelectorProps> = ({
  onSessionSelect,
  onNextSessionChange,
}) => {
  const [sessions, setSessions] = useState<moment.Moment[]>([]);
  const [selectedSession, setSelectedSession] = useState<string | null>(null);
  const [isToday, setIsToday] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [availableSessions, setAvailableSessions] = useState<moment.Moment[]>(
    []
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const generateSessions = () => {
      const now = moment().tz("America/Los_Angeles");
      const nextThursday = now.clone().day(4).hour(18).minute(0).second(0);

      if (now.isAfter(nextThursday.clone().subtract(1, "hour"))) {
        nextThursday.add(1, "week");
      }

      const sessionDates = [
        nextThursday.clone(),
        nextThursday.clone().add(1, "week"),
        nextThursday.clone().add(2, "weeks"),
        nextThursday.clone().add(3, "weeks"),
      ];

      setSessions(sessionDates);
      setAvailableSessions(sessionDates);
      onNextSessionChange(sessionDates[0]);

      // Check if today is the same day as the first session (nextThursday)
      setIsToday(now.isSame(sessionDates[0], "day"));
    };

    generateSessions();
    const interval = setInterval(generateSessions, 60000);

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      clearInterval(interval);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onNextSessionChange]);

  const handleSessionSelect = (session: moment.Moment) => {
    const sessionValue = session.format("YYYY-MM-DD HH:mm");
    if (selectedSession) {
      const previousSession = sessions.find(
        (s) => s.format("YYYY-MM-DD HH:mm") === selectedSession
      );
      if (previousSession) {
        setAvailableSessions((prev) => [...prev, previousSession]);
      }
    }
    setSelectedSession(sessionValue);
    onSessionSelect(sessionValue);
    setAvailableSessions((prev) =>
      prev.filter((s) => s.format("YYYY-MM-DD HH:mm") !== sessionValue)
    );
    setIsOpen(false);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const isSessionDisabled = (session: moment.Moment) => {
    const now = moment().tz("America/Los_Angeles");
    return now.isAfter(session.clone().subtract(1, "hour"));
  };

  return (
    <SessionSelectorContainer ref={dropdownRef}>
      <Label>Session</Label>
      <DropdownToggle onClick={toggleDropdown}>
        <LeftContent>
          <CalendarIcon>
            <FontAwesomeIcon icon={faCalendar} />
          </CalendarIcon>
          {selectedSession
            ? moment(selectedSession).format("MMM D [at] h:mma [(PST)]")
            : "Choose a session"}
        </LeftContent>{" "}
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
      </DropdownToggle>
      <DropdownMenu $isOpen={isOpen}>
        {availableSessions.map((session, index) => (
          <DropdownItem
            key={index}
            onClick={() =>
              !isSessionDisabled(session) && handleSessionSelect(session)
            }
            style={{ opacity: isSessionDisabled(session) ? 0.5 : 1 }}
            $isToday={index === 0 && isToday}
          >
            {session.format("MMM D [@] h:mma [(PST)]")}
            {isSessionDisabled(session) && " (Unavailable)"}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </SessionSelectorContainer>
  );
};

export default SessionSelector;
