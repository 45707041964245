// libs
import React from "react";
import styled from "styled-components";

// components
import HoverCell from "./HoverCell";

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-3);
`;

const Jason = styled.img`
  width: var(--space-5);
  aspect-ratio: 1/1;
  object-fit: contain;
`;

const TextAndImageWrapper = styled.div`
  display: flex;
  gap: var(--space-2);
  flex-direction: column-reverse;

  @media screen and (min-width: 480px) {
    flex-direction: row;
  }

  @media screen and (min-width: 768px) {
    flex-direction: column;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const ImageAndCaptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 480px) {
    flex-direction: column;
  }
`;

const Caption = styled.p`
  display: block;
  @media screen and (min-width: 480px) {
    text-align: center;
    font-size: var(--font-size-small);
  }
`;

const Headline: React.FC = () => {
  return (
    <HeadlineContainer>
      <TextAndImageWrapper>
        <ImageAndCaptionWrapper>
          <Jason src="https://yarden-garden.s3.us-west-1.amazonaws.com/the-garden-bootcamp/Jason.png" />
          <Caption>
            <b>Jason Coleman,&nbsp;</b>
            <br />
            CEO & Master Gardener
            <br />
            Yarden Inc.
          </Caption>
        </ImageAndCaptionWrapper>
        <div>
          <h3>About this webinar</h3>
          <p>
            Join our CEO, <b>Jason Coleman</b>, as he shares the secrets to
            success and some things you'll need to consider when you're just
            starting out as a gardener. In this <b>FREE</b>&nbsp; section we'll
            cover some basic gardening topics:
          </p>
        </div>
      </TextAndImageWrapper>
      <HoverCell />
    </HeadlineContainer>
  );
};

export default Headline;
