// libs
import axios from "axios";

type NewSignup = {
  firstName: string;
  lastName: string;
  email: string;
  session: string;
};

export async function createWebinarSignup(newSignup: NewSignup) {
  const response = await axios.post(`/api/v1/webinar-signup`, newSignup);
  return response.data;
}
