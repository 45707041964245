// libs
import styled from "styled-components";

// components
import Card from "./Card";

interface P {
  color?: string;
}

const Section2Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-3);
  @media (min-width: 992px) {
    margin-top: var(--space-3);
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-1);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--space-3);
  }

  @media (min-width: 992px) {
    gap: var(--space-4);
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: var(--space-2);
`;

const TextContent = styled.div`
  padding: 0;
`;

const Heading = styled.h3`
  font-weight: bold;
`;

const Paragraph = styled.p<P>`
  color: ${(props) => props.color};
`;

const CardsContainer = styled.div`
  padding-top: var(--space-2);
  display: grid;
  gap: var(--space-2);
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledCard = styled(Card)`
  padding: var(--space-2);
  border-radius: 1rem;
`;

const CardHeading = styled.h4`
  color: var(--purpleB);
`;

const BuyCourseButton = styled.button`
  width: 100%;
  margin-top: var(--space-2);
  @media (min-width: 768px) {
    width: fit-content;
  }
`;

const Section2 = () => {
  return (
    <Section2Container>
      <ContentContainer>
        <Image
          src="https://yarden-garden.s3.us-west-1.amazonaws.com/the-garden-bootcamp/woman-using-online-gardening-course.png"
          alt="A Woman using a gardening course on her computer"
        />
        <TextContent>
          <Heading>
            Learn everything you need about gardening to know with our online
            course.
          </Heading>
          <Paragraph>
            Discover the joys of gardening without ever leaving your doorstep!
            With our 4-week online gardening course, you can learn everything
            you need to know about growing plants, herbs, and vegetables right
            from the comfort of your home.
          </Paragraph>
          <BuyCourseButton
            style={{ marginTop: 25 }}
            onClick={() =>
              (window.location.href =
                "https://school.thegardenbootcamp.com/purchase?product_id=5742338")
            }
          >
            Buy Course $99.00
          </BuyCourseButton>
        </TextContent>
      </ContentContainer>
      <CardsContainer>
        <StyledCard>
          <CardHeading>Detailed Lessons</CardHeading>
          <Paragraph>
            In our online gardening course means you'll dive deep into every
            aspect of gardening—from soil preparation to advanced plant care
            techniques
          </Paragraph>
        </StyledCard>
        <StyledCard>
          <CardHeading>Knowledge Tests</CardHeading>
          <Paragraph>
            Engaging quizzes and practical challenges will ensure you can
            confidently apply your gardening knowledge to real-world situations
          </Paragraph>
        </StyledCard>
        <StyledCard>
          <CardHeading>Live Lectures</CardHeading>
          <Paragraph>
            Interactive sessions with our exprt gardening instructors provide an
            opportunity to dive deeper into gardening topics and ask questions
          </Paragraph>
        </StyledCard>
      </CardsContainer>
    </Section2Container>
  );
};

export default Section2;
