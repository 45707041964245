// libs
import styled from "styled-components";

const SectionContainer = styled.div`
  padding: 2rem 1rem;
  margin-top: var(--space-4);
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: var(--space-2);
`;

const TestimonialsGrid = styled.div`
  display: grid;
  gap: var(--space-3);
  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--space-3);
  }
`;

const TestimonialCard = styled.div`
  display: flex;
  justify-content: center;
`;

const TestimonialContent = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
`;

const TestimonialImage = styled.img`
  border-radius: 50%;
  width: 100px;
  margin: 0 auto 0.5rem auto;
`;

const Author = styled.h4`
  margin-bottom: 0.5rem;
  text-align: center;
`;

const TestimonialParagraph = styled.p`
  max-width: 40ch;
`;

const Section4 = () => {
  return (
    <SectionContainer>
      <Title>See What Our Students Are Saying</Title>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TestimonialsGrid>
          {[
            "https://yarden-garden.s3.us-west-1.amazonaws.com/the-garden-bootcamp/testimonial-1.jpg",
            "https://yarden-garden.s3.us-west-1.amazonaws.com/the-garden-bootcamp/testimonial-2.jpg",
            "https://yarden-garden.s3.us-west-1.amazonaws.com/the-garden-bootcamp/testimonial-3.jpg",
          ].map((testimonial, index) => (
            <TestimonialCard key={index}>
              <TestimonialContent>
                <TestimonialImage
                  src={testimonial}
                  alt={`testimonial ${index + 1}`}
                />
                <Author>{["Kate S.", "Ross C.", "Nabil L."][index]}</Author>
                <TestimonialParagraph>
                  {
                    [
                      "The instructors were very helpful in explaining gardening concepts in a way that helped me understand.",
                      "I really enjoyed my time at The Garden Bootcamp. It helped prepare me for growing my own garden at home.",
                      "10 / 10 experience! I highly recommend this course to anyone who is considering a growing their own garden.",
                    ][index]
                  }
                </TestimonialParagraph>
              </TestimonialContent>
            </TestimonialCard>
          ))}
        </TestimonialsGrid>
      </div>
    </SectionContainer>
  );
};

export default Section4;
