import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChalkboard,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";

const NavLinks = styled.ul`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const NavLink = styled.li`
  list-style: none;

  a {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    gap: 0;
    height: var(--space-3);

    &:hover {
      text-decoration: none;
      background: var(--purpleD-10);
    }
  }
`;

const IconWrapper = styled.span`
  display: flex;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  height: 2em;
`;

const navigation = [
  {
    name: "Webinar",
    path: "/webinar",
    icon: faChalkboard,
  },
  {
    name: "Bootcamp",
    path: "/",
    icon: faGraduationCap,
  },
];

const NavList = () => {
  return (
    <NavLinks id="nav-links">
      {navigation.map((nav) => (
        <NavLink key={nav.name}>
          <a href={nav.path}>
            <IconWrapper>
              <FontAwesomeIcon icon={nav.icon} />
            </IconWrapper>
            {nav.name}
          </a>
        </NavLink>
      ))}
    </NavLinks>
  );
};

export default NavList;
