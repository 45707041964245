// libs
import styled from "styled-components";

// components
import SvgRounder from "./SvgRounder";

const SectionContainer = styled.div`
  position: relative;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ContentCard = styled.div`
  background-color: white;
  padding: var(--space-2) 0;
  margin-top: var(--space-3);
  display: flex;
  align-items: center;
  z-index: 10;
  opacity: 1;

  @media (min-width: 768px) {
    opacity: 0.9;
  }
`;

const StyledHeading = styled.h2`
  font-weight: bold;
  margin-bottom: var(--space-2);
`;

const BackgroundContainer = styled.div<{ $image: string }>`
  position: relative;
  background-image: url(${(props) => props.$image});
  background-size: cover;
  background-position: center;
  height: var(--space-6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--space-3);
`;

const SvgContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
`;

const TopicNumber = styled.h2`
  position: absolute;
  top: 0;
  left: 0;
  color: var(--purpleB);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
`;

const CurriculumSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
`;

const ItemText = styled.div`
  padding: 0; // i made this just so there would be a change that i could commit. delete this line later
  padding: var(--space-2);
`;

const curriculumData = [
  {
    week: 1,
    title: "Installing Garden Beds",
    description:
      "In the first week, you'll learn how to choose the right location, prepare the soil, and install garden beds tailored to your specific needs. By understanding the best practices for installation, you'll create a solid foundation that promotes healthy plant growth and a bountiful harvest.",
    image:
      "https://yarden-garden.s3.us-west-1.amazonaws.com/the-garden-bootcamp/installing-garden-beds.png",
  },
  {
    week: 2,
    title: "Initial Planting",
    description:
      "The second week covers everything you need to know to get your first plants in the ground, from selecting the right seedlings or seeds to properly spacing them and understanding when and how to plant for optimal growth.",
    image:
      "https://yarden-garden.s3.us-west-1.amazonaws.com/the-garden-bootcamp/initial-planting.png",
  },
  {
    week: 3,
    title: "Maintenance",
    description:
      "During the third week, you'll learn essential care practices like watering, weeding, pruning, and pest management. By understanding how to maintain your garden properly, you'll ensure that your plants stay vigorous and continue to produce abundant yields.",
    image:
      "https://yarden-garden.s3.us-west-1.amazonaws.com/the-garden-bootcamp/maintenance.png",
  },
  {
    week: 4,
    title: "Seasonal Crop Rotation",
    description:
      "In the fourth week, you'll learn how to rotate your crops effectively, ensuring that soil nutrients are balanced, pests are minimized, and plant diseases are kept at bay.",
    image:
      "https://yarden-garden.s3.us-west-1.amazonaws.com/the-garden-bootcamp/crop-rotation.png",
  },
];

const Section3 = () => {
  return (
    <SectionContainer>
      <FlexContainer>
        <ContentCard>
          <div style={{ width: "100%" }}>
            <StyledHeading>
              Curriculum Built By Gardeners, For Gardeners.
            </StyledHeading>
            <CurriculumSection>
              {curriculumData.map((item, index) => (
                <div className="auto-grid-large" key={index}>
                  <BackgroundContainer $image={item.image}>
                    <SvgContainer>
                      <SvgRounder
                        cornerRadius={27}
                        width="90px"
                        height="90px"
                        fill="white"
                        stroke="none"
                        strokeWidth="2"
                        pathData={`
                    M0 0
                    L90 0
                    L60 0
                    L60 60
                    L0 60
                    L0 90
                    Z
                  `}
                      />
                      <TopicNumber>{index + 1}</TopicNumber>
                    </SvgContainer>
                  </BackgroundContainer>
                  <ItemText>
                    <label>Week {item.week}</label>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </ItemText>
                </div>
              ))}
            </CurriculumSection>
          </div>
        </ContentCard>
      </FlexContainer>
    </SectionContainer>
  );
};

export default Section3;
