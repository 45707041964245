import React from 'react'
import styled from 'styled-components'
import NavList from './NavList'


const DesktopNavContainer = styled.nav`
    display: none;

    @media screen and (min-width: 768px) {
        display: flex;
    }
`


const DesktopNav: React.FC = () => {
  return (
    <DesktopNavContainer>
      <NavList />
    </DesktopNavContainer>
  )
}

export default DesktopNav
