import React, { forwardRef } from "react";
import NavList from "./NavList";
import styled from "styled-components";

const MobileNavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: var(--space-2);
  top: 63px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #fff;
  border-radius: 1em;
  backdrop-filter: blur(10px);
  overflow: hidden;
  border: 1px solid var(--greenD-10);
  box-shadow: 0 6px 12px var(--greenD-10);

  ul li {
    a {
      padding: 1.5em 1em;

      &:hover {
        background: var(--purpleD-10);
      }
    }
  }
`;


const MobileNav = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <MobileNavContainer ref={ref} id="mobile-nav">
      <NavList />
    </MobileNavContainer>
  );
});

export default MobileNav;
