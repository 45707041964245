// libs
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// components
import { Input } from "./Input";
import Button from "./Button";
import SessionSelector from "./SessionSelector";
import Countdown from "./Countdown";

// requests
import { createWebinarSignup } from "../requests/webinar-signup";

const SignupFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  align-items: center;
  overflow: visible;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  width: 100%;
  max-width: var(--space-7);
`;

const NameInputContainer = styled.div`
  display: flex;
  gap: 1em;
  width: 100%;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const ButtonContainer = styled.div`
  display: flex;

  button {
    width: 100%;
    &:disabled {
      filter: grayscale(0.5);
      cursor: not-allowed;
    }
  }
`;

const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const [nextSession, setNextSession] = useState<moment.Moment | null>(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    session: null as string | null,
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    session: "",
  });

  const [validity, setValidity] = useState({
    firstName: null,
    lastName: null,
    email: null,
  });

  const [formIsValid, setFormIsValid] = useState(false);

  const validateInput = (name: string, value: string) => {
    switch (name) {
      case "firstName":
      case "lastName":
        return /^[a-zA-Z\s-]+$/.test(value);
      case "email":
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
      default:
        return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let sanitizedValue = value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: sanitizedValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    const isValid =
      value.trim() !== "" ? validateInput(name, sanitizedValue) : null;
    setValidity((prevValidity) => ({
      ...prevValidity,
      [name]: isValid,
    }));
  };

  const handleSessionSelect = (session: string | null) => {
    setFormData((prevState) => ({ ...prevState, session }));
    setErrors((prevErrors) => ({ ...prevErrors, session: "" }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newErrors = {
      firstName: formData.firstName ? "" : "Name is required",
      lastName: formData.lastName ? "" : "Last name is required",
      email: formData.email ? "" : "Email is required",
      session: formData.session ? "" : "Session selection is required",
    };
    setErrors(newErrors);

    if (!Object.values(newErrors).some((error) => error)) {
      try {
        if (formData.session) {
          const dateTime = moment
            .tz(formData.session, "YYYY-MM-DD HH:mm", "America/Los_Angeles")
            .format("MM/DD/YYYY @ h:mma");
          await createWebinarSignup({
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            session: dateTime,
          });
          navigate(`/webinar-confirmation?session=${dateTime}`);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    const isFormValid =
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      formData.session &&
      validity.firstName &&
      validity.lastName &&
      validity.email;

    setFormIsValid(!!isFormValid);
  }, [formData, validity]);

  return (
    <SignupFormContainer className="signup-form">
      {nextSession && <Countdown targetDate={nextSession} />}

      <hr />

      {errors.session && (
        <span style={{ color: "var(--danger)" }}>{errors.session}</span>
      )}
      <Form onSubmit={handleSubmit}>
        <SessionSelector
          onSessionSelect={handleSessionSelect}
          onNextSessionChange={setNextSession}
        />
        <NameInputContainer>
          <Input
            label="First Name"
            error={errors.firstName}
            helperText="Only letters, spaces, and hyphens are allowed"
            $fullWidth={true}
            id="first-name-input"
            name="firstName"
            placeholder="Ex. Jane"
            value={formData.firstName}
            onChange={handleChange}
            required
            pattern="[a-zA-Z\s-]+"
            isValid={validity.firstName}
          />

          <Input
            label="Last Name"
            error={errors.lastName}
            helperText="Only letters, spaces, and hyphens are allowed"
            $fullWidth={true}
            id="last-name-input"
            name="lastName"
            placeholder="Ex. Doe"
            value={formData.lastName}
            onChange={handleChange}
            required
            pattern="[a-zA-Z\s-]+"
            isValid={validity.lastName}
          />
        </NameInputContainer>

        <Input
          label="Email"
          error={errors.email}
          helperText="Please enter a valid email address"
          $fullWidth={true}
          id="email-input"
          name="email"
          placeholder="Ex. jane@doe.com"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
          isValid={validity.email}
        />

        <ButtonContainer>
          <Button type="submit" disabled={!formIsValid}>
            Save My Spot!
          </Button>
        </ButtonContainer>
      </Form>
      <div>
        <hr />
        <p>
          The deadline to sign up for a session is 1 hour before the session
          starts.
        </p>
      </div>
    </SignupFormContainer>
  );
};

export default SignUpForm;
