const HeroSection = () => {
  return (
    <div
      style={{
        backgroundImage: `url(https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-37.jpeg)`,
        height: "calc(100vh - 87px)",
      }}
      className="hero-section"
    >
      <div className="overlay"></div>
      <div className="hero-message">
        <h1 style={{ marginBottom: 15 }}>
          <b>The Garden Bootcamp</b>
        </h1>
        <p
          style={{
            fontSize: "var(--font-size-h3",
            paddingInline: "var(--space-2)",
          }}
        >
          Become the gardener you've always wanted to be.
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
