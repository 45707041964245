//libs
import styled from "styled-components";

// components
import Screen from "../../components/Screen";
import HeroSection from "../../components/HeroSection";
import Section1 from "../../components/Section1";
import Section2 from "../../components/Section2";
import Section3 from "../../components/Section3";
import Section4 from "../../components/Section4";
import Section5 from "../../components/Section5";

const BootcampPageContainer = styled.div`
  padding-top: 87px;
`;

const BootcampPage = () => {
  return (
    <BootcampPageContainer>
      <HeroSection />
      <Screen id="bootcamp-page">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
      </Screen>
    </BootcampPageContainer>
  );
};

export default BootcampPage;
