// components
import Screen from "../../components/Screen";
import SignUpForm from "../../components/SignUpForm";
import Headline from "../../components/Headline";

// styling
import styled from "styled-components";

const LayoutGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-3);

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 992px) {
    gap: var(--space-4);
  }
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: var(--space-3);
  padding-top: 16rem;
`;

const HeadlineText = styled.h1`
  color: var(--purpleB);
  font-size: clamp(var(--font-size-h2), 10vw, 8rem);
`;
const WebinarLandingPage = () => {
  return (
    <Screen id="webinar-screen">
      <Header>
        <label>A Yarden Masterclass</label>
        <HeadlineText>Gardening Basics: FREE Webinar</HeadlineText>
      </Header>
      <LayoutGrid>
        <Headline />
        <SignUpForm />
      </LayoutGrid>
    </Screen>
  );
};

export default WebinarLandingPage;
