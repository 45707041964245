import React, { useRef } from "react";
import styled from "styled-components";
import SvgRounder from "./SvgRounder";
import Irrigation from "./videos/irrigation.mp4";
import Soil from "./videos/soil.mp4";
import Sunlight from "./videos/sunlight.mp4";

const HoverCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--space-7);
  border-radius: var(--space-3);
  overflow: hidden;
  gap: var(--space-1);

  @media screen and (min-width: 576px) {
    flex-direction: row;
    height: var(--space-6);
  }

  @media screen and (min-width: 768px) {
    flex-direction: column;
    height: var(--space-7);
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
    height: calc(var(--space-6) + var(--space-3));
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Bouncy transition */
  position: relative;
  overflow: hidden;
  border-radius: var(--space-3);
  border: 1px solid white;
  min-height: var(--space-5);

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Bouncy transition for video */
    filter: grayscale(1);
    border-radius: var(--space-3);
    overflow: hidden;
  }

  &:hover {
    flex: 2;
    filter: grayscale(0);
    ${({ theme }) => theme.CellText} {
      height: auto;
    }
    video {
      filter: grayscale(0);
    }
  }
`;

const CellTitle = styled.h3`
  color: white;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (min-width: 480px) {
    overflow: hidden;
  }

  @media screen and (min-width: 768px) {
    overflow: unset;
  }
`;

const CellDescription = styled.p`
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  font-size: 0;

  ${Cell}:hover & {
    opacity: 1;
    transition: opacity 0.3s ease-in-out 0.5s,
      font-size 0.2s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s; /* Snappy and bouncy font-size transition */
    font-size: var(--font-size-h5);
  }
`;

const CellText = styled.div`
  position: absolute;
  background: #330099aa;
  padding: 1em;
  backdrop-filter: blur(6px);
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  top: 100%;
  transform: translateY(-100%);
  width: 100%;
  border-radius: 0 0 var(--space-3) var(--space-3);
`;

const SvgContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  padding-left: 0;
  padding-bottom: var(--space-0);
`;

const TopicNumber = styled.h2`
  position: absolute;
  top: 0;
  left: 0;
  color: var(--purpleB);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
`;

const HoverCell: React.FC = () => {
  const cellRefs = useRef<(HTMLDivElement | null)[]>([]);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  const cellData = [
    {
      label: "Irrigation",
      videoSrc: Irrigation,
      color: "#4CAF50",
      description: "Learn about proper irrigation techniques for your garden.",
    },
    {
      label: "Soil",
      videoSrc: Soil,
      color: "#795548",
      description: "Discover the importance of soil quality and composition.",
    },
    {
      label: "Sunlight",
      videoSrc: Sunlight,
      color: "#FFC107",
      description: "Understand how sunlight affects plant growth and health.",
    },
  ];

  const handleMouseEnter = (index: number) => {
    const video = videoRefs.current[index];
    if (video && video.paused) {
      setTimeout(() => {
        if (video.paused) {
          video
            .play()
            .catch((error) => console.log("Play interrupted:", error));
        }
      }, 50);
    }
  };

  const handleMouseLeave = (index: number) => {
    const video = videoRefs.current[index];
    if (video && !video.paused) {
      setTimeout(() => {
        if (video) {
          video.pause();
          video.currentTime = 0;
        }
      }, 50);
    }
  };

  return (
    <Wrapper>
      <div className="hide-on-mobile">
        <Label>Webinar Topics</Label>
        <HoverCellContainer>
          {cellData.map((cell, index) => (
            <Cell
              key={index}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <video
                ref={(el) => (videoRefs.current[index] = el)}
                src={cell.videoSrc}
                loop
                muted
                playsInline
              />
              <CellText ref={(el: any) => (cellRefs.current[index] = el)}>
                <CellTitle>{cell.label}</CellTitle>
                <CellDescription>{cell.description}</CellDescription>
              </CellText>
              <SvgContainer>
                <SvgRounder
                  cornerRadius={27} // Equivalent to 4rem
                  width="90px"
                  height="90px"
                  fill="white"
                  stroke="none"
                  strokeWidth="2"
                  pathData={`
                  M0 0
                  L90 0
                  L60 0
                  L60 60
                  L0 60
                  L0 90
                  Z
                `}
                />
                <TopicNumber>{index + 1}</TopicNumber>
              </SvgContainer>
            </Cell>
          ))}
        </HoverCellContainer>
      </div>
    </Wrapper>
  );
};

export default HoverCell;
