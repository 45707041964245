import styled from "styled-components";
import { useEffect, useRef } from "react";
import Logo from "./logos/yarden_horizontal_lockup_wg_fitted.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
const FooterContainer = styled.footer`
  height: var(--footer-height, auto);
  background: var(--purpleB);
  color: white;
  width: 100vw;
  height: fit-content;
  padding: 1em;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: var(--space-8);
  margin: 0 auto;
  flex-wrap: wrap;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: var(--space-2);
  align-items: center;
`;

const SocialIcon = styled.a`
  color: white;
  font-size: var(--font-size-h3);
  transition: color 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    color: var(--purple4);
    transform: scale(1.1);
  }
`;

const LogoImg = styled.img`
  width: var(--space-5);
`;

const Footer = () => {
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateFooterHeight = () => {
      if (footerRef.current) {
        const height = footerRef.current.offsetHeight;
        document.documentElement.style.setProperty(
          "--footer-height",
          `${height}px`
        );
      }
    };

    updateFooterHeight();
    window.addEventListener("resize", updateFooterHeight);

    return () => {
      window.removeEventListener("resize", updateFooterHeight);
    };
  }, []);

  return (
    <FooterContainer ref={footerRef}>
      <InnerWrapper>
        <SocialIcons>
          <SocialIcon
            href="https://www.facebook.com/yardengardeninc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </SocialIcon>
          <SocialIcon
            href="https://x.com/official_yarden"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faXTwitter} />
          </SocialIcon>
          <SocialIcon
            href="https://www.instagram.com/official_yarden/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </SocialIcon>
          <SocialIcon
            href="https://www.linkedin.com/company/officialyarden"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </SocialIcon>
        </SocialIcons>
        <LogoImg src={Logo} alt="Yarden Logo" />
      </InnerWrapper>
    </FooterContainer>
  );
};

export default Footer;
