// libs
import { ReactNode } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// screens
import Bootcamp from "../screens/bootcamp/Bootcamp";
import Webinar from "../screens/webinar/Webinar";
import WebinarConfirmation from "../screens/webinar-confirmation/WebinarConfirmation";

// components
import Navbar from "./Header";
import Footer from "./Footer";
import TitleManager from "./TitleManager";

const route = (
  screen: ReactNode,
  showNavbar: boolean = true,
  showFooter: boolean = true
) => {
  return (
    <>
      {showNavbar && <Navbar />}
      {screen}
      {showFooter && <Footer />}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <TitleManager />
      <Routes>
        <Route path="/" element={route(<Bootcamp />)} />
        <Route path="/webinar" element={route(<Webinar />)} />
        <Route
          path="/webinar-confirmation"
          element={route(<WebinarConfirmation />)}
        />
      </Routes>
    </Router>
  );
};

export default App;
