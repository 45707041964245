// libs
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";

const Divider = styled.div`
  width: 50%;
  height: 1px; /* Adjust the height as needed */
  background-color: var(--purpleB);
  margin: 1rem 0;
`;

const WebinarConfirmation = () => {
  const urlParams = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );

  const datetime = urlParams.session ? urlParams.session : "N/A";

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: 35, color: "var(--purpleB)" }}>
              <FontAwesomeIcon icon={faThumbsUp} />
            </span>
          </div>
          <div style={{ textAlign: "center" }}>
            <h4 style={{ marginBottom: 15 }}>Success!</h4>
            <p>You have been signed up for the webinar.</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 25,
                marginBottom: 25,
              }}
            >
              <Divider />
            </div>
            <h5>Date / Time</h5>
            {datetime}
            <h5 style={{ marginTop: 15 }}>Meeting Link</h5>
            Check your email inbox
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarConfirmation;
