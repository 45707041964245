// libs
import styled from "styled-components";

const SectionContainer = styled.div`
  position: relative;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ContentCard = styled.div`
  background-color: white;
  padding: 2rem 0;
  margin-top: var(--space2);
  display: flex;
  align-items: center;
  z-index: 10;
  opacity: 1;

  @media (min-width: 992px) {
    padding: var(--space-3) 0 0 0;
  }
`;

const StyledHeading = styled.h2`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const StyledParagraph = styled.p`
  margin-bottom: 0.5rem;
`;

const Section1 = () => {
  return (
    <SectionContainer>
      <FlexContainer>
        <ContentCard>
          <div>
            <div>
              <StyledHeading>
                Gardening is complicated. Learning it shouldn't be.
              </StyledHeading>
              <StyledParagraph>
                We simplify gardening by breaking down tough concepts into
                easy-to-follow lessons, offering hands-on practice, and
                providing support every step of the way. Whether you're just
                starting or looking to advance, we make learning gardening clear
                and accessible.
              </StyledParagraph>
            </div>
          </div>
        </ContentCard>
      </FlexContainer>
    </SectionContainer>
  );
};

export default Section1;
