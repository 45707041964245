import React, { useEffect, useRef } from 'react';
import { parsePath, roundCommands } from 'svg-round-corners';

interface SvgRounderProps {
  width?: number | string;
  height?: number | string;
  fill?: string;
  stroke?: string;
  strokeWidth?: number | string;
  cornerRadius: number | string;
  pathData: string;
}

const SvgRounder: React.FC<SvgRounderProps> = ({
  width = "100%",
  height = "100%",
  fill = "none",
  stroke = "none",
  strokeWidth = "1",
  cornerRadius,
  pathData,
}) => {
  const pathRef = useRef<SVGPathElement>(null);

  useEffect(() => {
    if (pathRef.current) {
      const radius = typeof cornerRadius === "string" ? parseFloat(cornerRadius) : cornerRadius;
      const parsedPath = parsePath(pathData);
      const roundedPathData = roundCommands(parsedPath, radius, 2); // 2 decimal places by default
      pathRef.current.setAttribute('d', roundedPathData.path);
    }
  }, [pathData, cornerRadius]);

  return (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width={width} 
        height={height}>
      <path
        ref={pathRef}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default SvgRounder;
