// libs
import styled from "styled-components";

const SectionContainer = styled.div`
  font-family: "Roboto Thin", sans-serif;
  padding: 2rem;
`;

const Heading = styled.h2`
  padding: 0;
`;

const Subheading = styled.p`
  margin-bottom: 2rem;
`;

const ParentContainer = styled.div`
  position: relative;
  margin-top: var(--space-4);
`;

const ChildElement = styled.div`
  display: grid;
  gap: var(--space-3);

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: var(--space-3);
`;

const ContentContainer = styled.div`
  display: grid;
  gap: var(--space-3);
`;

const ContentBlock = styled.div`
  h5 {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
  }
`;

const Divider = styled.div`
  width: 50%;
  height: 1px; /* Adjust the height as needed */
  background-color: var(--purpleB);
  margin: 1rem 0;
`;

const Section5 = () => {
  return (
    <SectionContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <Divider />
      </div>
      <Heading>
        <b>Continued Support After Graduation.</b>
      </Heading>
      <Subheading>
        Our online garden bootcamp continues to support students even after
        graduation, ensuring they have ongoing guidance as they begin their own
        gardening journey. Graduates gain access to a community of fellow
        gardeners where they can share experiences, ask questions, and learn
        from each other. Additionally, they receive exclusive resources such as
        seasonal gardening guides, troubleshooting tips, and expert advice to
        help them overcome challenges they might face.
      </Subheading>
      <ParentContainer>
        <ChildElement>
          <Image
            src="https://yarden-garden.s3.us-west-1.amazonaws.com/the-garden-bootcamp/gardeners-talking.png"
            alt="Gardeners talking"
          />
          <div>
            <ContentContainer>
              <ContentBlock>
                <h4>Lifetime Access to Gardening Resources</h4>
                <p>
                  Graduates receive lifetime access to exclusive gardening
                  resources, including seasonal guides, plant care tips, and
                  troubleshooting manuals to help them navigate their gardening
                  journey.
                </p>
              </ContentBlock>
              <ContentBlock>
                <h4>Community Support Network</h4>
                <p>
                  Graduates become part of an active community of fellow
                  gardeners, where they can share experiences, ask questions,
                  and receive advice from both peers and experts.
                </p>
              </ContentBlock>
              <ContentBlock>
                <h4>Exclusive Workshops and Webinars</h4>
                <p>
                  Graduates are invited to participate in special workshops and
                  webinars led by gardening experts, focusing on advanced topics
                  like organic gardening, composting, and landscape design.
                </p>
              </ContentBlock>
              <ContentBlock>
                <h4>Seasonal Garden Planning Assistance</h4>
                <p>
                  Graduates receive help with planning their gardens seasonally,
                  including which crops to plant and when, ensuring they make
                  the most out of their gardening space throughout the year.
                </p>
              </ContentBlock>
            </ContentContainer>
          </div>
        </ChildElement>
      </ParentContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <Divider />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <h3>Start Your Gardening Education Today</h3>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
          >
            <button
              onClick={() =>
                (window.location.href =
                  "https://school.thegardenbootcamp.com/purchase?product_id=5742338")
              }
            >
              Buy Course $99.00
            </button>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

export default Section5;
