// libs
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// vars
import { PRODUCT_NAME } from "../vars/company";

const TitleManager = () => {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        document.title = `${PRODUCT_NAME} - An online course for gardening`;
        break;
      case "/webinar":
        document.title = `${PRODUCT_NAME} - Webinar`;
        break;
      case "/webinar-confirmation":
        document.title = `${PRODUCT_NAME} - Webinar Confirmation`;
        break;
      default:
        document.title = `${PRODUCT_NAME}`;
        break;
    }
  }, [location]);

  return null;
};

export default TitleManager;
