import React from 'react';
import styled from 'styled-components';

const ScreenContainer = styled.main`
  display: flex;
  flex-direction: column;
  max-width: var(--space-8);
  padding-inline: var(--space-2);
  padding-bottom: var(--space-2);
  min-height: calc(100dvh - var(--header-height) - var(--space-3));
  overflow-x: hidden;

  @media screen and (min-width: 576px) {
    padding-inline: var(--space-3);
  }

  @media screen and (min-width: 1200px) {
    margin: 0 auto;
    padding-inline: 0;
  }
`;

interface ScreenProps {
  children: React.ReactNode;
  id?: string;
}

const Screen: React.FC<ScreenProps> = ({ children, id }) => {
  return (
    <ScreenContainer id={id}>
      {children}
    </ScreenContainer>
  );
};

export default Screen;
