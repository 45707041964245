import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

const CountdownContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--space-3);
  color: var(--greenD-75);
`;

const TimeUnit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--space-1);
`;

const Number = styled.data`
  font-size: var(--font-size-h3);
  font-weight: bold;
  line-height: 1;

  @media screen and (min-width: 480px) {
    font-size: var(--font-size-h1);
  }

  @media screen and (min-width: 768px) {
    font-size: var(--font-size-h2);
  }

  @media screen and (min-width: 992px) {
    font-size: var(--font-size-h1);
  }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Label = styled.label`
    padding-left: var(--space-1);
`;

const NextSession = styled.data`
    text-align: center;
    font-family: var(--font-family-data);
    color: var(--success);
`;

const padNumber = (num: number): string => {
  return num.toString().padStart(2, "0");
};

interface CountdownProps {
  targetDate: moment.Moment;
}

const Countdown: React.FC<CountdownProps> = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const target = targetDate.valueOf();

    const interval = setInterval(() => {
      const now = moment().valueOf();
      const difference = target - now;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });

      if (difference < 0) {
        clearInterval(interval);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <Wrapper>
    <Label>Time until next session</Label>
    <CountdownContainer>
      <TimeUnit>
        <Number>{padNumber(timeLeft.days)}</Number>
        <span className="label">Days</span>
      </TimeUnit>
      :
      <TimeUnit>
        <Number>{padNumber(timeLeft.hours)}</Number>
        <span className="label">Hours</span>
      </TimeUnit>
      :
      <TimeUnit>
        <Number>{padNumber(timeLeft.minutes)}</Number>
        <span className="label">Minutes</span>
      </TimeUnit>
      :
      <TimeUnit>
        <Number>{padNumber(timeLeft.seconds)}</Number>
        <span className="label">Seconds</span>
      </TimeUnit>
    </CountdownContainer>
    <NextSession>{targetDate.format('MMMM D, YYYY @ h:mm A')}</NextSession>
    </Wrapper>
  );
};

export default Countdown;
