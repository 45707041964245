import React, { ReactNode } from "react";
import styled from "styled-components";

interface CardProps {
  children: ReactNode;
  className?: string;
}

const StyledCard = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  background-color: white;
`;

const Card: React.FC<CardProps> = ({ children, className }) => {
  return <StyledCard className={className}>{children}</StyledCard>;
};

export default Card;
